$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    // Toggle the "hovering" class on elements
    // $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    $('.accHandler').accordion({openFirst:true});

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        maxWidth: $(window).width() * 0.8,
        maxHeight: $(window).height() * 0.8,
        shrinkFactor:1,
        type: 'image'
    });

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC.
     */
    // $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
    //     showCaption: false,
    //     labels: lightcaseLabels,
    //     type: 'iframe',
    //     maxWidth: $(window).width() * 0.8,
    //     maxHeight: $(window).height() * 0.8,
    //     iframe: {
    //         width: '600',
    //         height: $(window).height() * 0.8
    //     }
    // });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */

    /**
     * detect external links and add target=_blank
     */
    $('a:external').attr('target', '_blank');


    /**
     * PREVENTS CONTENT SCROLLING AFTER TOGGELING
     */
    // naviToggled.onchange('change');

});
/////////////// END DOC READY

/**
* PLACE YOUR OWN SCRIPTS HERE
*/
(function() {
    'use strict';

   $('.teaser-carousel').lightSlider({
       item:2,
       slideMove:1,
       auto: true,
       loop: true,
       controls: false,
       pager: false,
       easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
       speed:2000,
       pause: 6000,
       slideMargin:35,
       responsive : [
           {
               breakpoint:991,
               settings: {
                   item:1,
                   slideMove:1,
                   slideMargin: 0,
                   pager: true
                 }
           }
       ]
   }); //lightslider

   //FIX FOR OPENING SUBNAVI FOR TOUCH DEVICES
   // Define window size - width and height 
     var windowWidth,
        windowHeight,
        smallscreen;
     
     // Execute "Screen detection" function
     screenDetection();
     $(window).on('resize', function() {
       screenDetection();
     });
     
     // Screen detection
     function screenDetection() {
       // Calculate windows size
       windowWidth  = window.innerWidth;
       windowHeight = window.innerHeight;
       
       // Define current screen resolution (set true/false to the defined screen resolutions)
       smallscreen  = (windowWidth < 992) ? true : false  || $('body').hasClass('android') || $('body').hasClass('ipad') || $('body').hasClass('iphone');
     }

     $('.mobile .navi-main').find('li.hasSub > span, li.hasSub > a').click(function(e){
               var $parent = $(this).parent();
               var $submenu = $(this).next('ul');
               
               if($parent.hasClass('open')){
                   $submenu.stop(true,true).slideUp(400);
                   $parent.removeClass('open');
               }else{
                   e.preventDefault(e);
                   $submenu.stop(true,true).slideDown(400);
                   $parent.addClass('open');
               }
           });

     if (smallscreen) {
         openMobileNav();
       }
       $(window).resize(function() {
         if (smallscreen) {
           openMobileNav();
         }
       });

       function openMobileNav(){
           $('.win .navi-main, .android .navi-main, .ipad .navi-main, .iphone .navi-main').find('li.hasSub > span, li.hasSub > a').click(function(e){
               var $parent = $(this).parent();
               var $submenu = $(this).next('ul');
               
               if($parent.hasClass('open')){
               }else{
                   e.preventDefault(e);
                   $submenu.stop(true,true).slideDown(400);
                   $parent.addClass('open');
               }
           });
       }

      //open flying btns on the right :P
      if($('body').hasClass('mobile')) {
        $('.flying-icon-box').on('click', function() {
          var $this = $(this); 
          if(!$this.hasClass('opened')) {
            $(this).addClass('opened');
            $(this).removeClass('closed');
          } else {
            $(this).removeClass('opened');
            $(this).addClass('closed');
          }
        });
      }

})();